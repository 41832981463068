import React from "react";

const Ourportfolios = () => {
  return (
    <>
      {/* <!-- blog-news start Our Portfolio --> */}
      <section className="blog-news bg-dark_white pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div
                className="blog-news__content wow fadeInUp"
                data-wow-delay=".3s"
              >
                <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block">
                  <img
                    src="assets/img/team-details/badge-line.svg"
                    className="img-fluid mr-10"
                    alt=""
                  />
                  Our Portfolio
                </span>
                <h2 className="title color-d_black">
                  Amazing Work <span>Showcase</span>
                </h2>
              </div>
            </div>

            <div className="col-lg-3">
              <div
                className="blog-news__content mt-md-25 mt-sm-15 mt-xs-10 text-start text-lg-end wow fadeInUp"
                data-wow-delay=".3s"
              >
                <a href="#" className="theme-btn">
                  View All Sowcase{" "}
                  <i className="far fa-chevron-double-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="blog-news__bottom mt-65 mt-sm-50 mt-xs-40">
            <div className="row mb-minus-30">
              <div className="col-lg-4 col-sm-6">
                <div
                  className="our-project__item overflow-hidden mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <img src="assets/img/projects/project-1.png" alt="" />

                  <div className="content d-flex align-items-center justify-content-between">
                    <div className="text">
                      <span className="fw-500 color-primary d-block mb-10 text-uppercase">
                        Business, Finance
                      </span>
                      <h5 className="title color-d_black">
                        Business & Strategy
                      </h5>
                    </div>

                    <a href="#" className="theme-btn">
                      <img src="assets/img/icon/arrow-right-top.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-sm-6">
                <div
                  className="our-project__item overflow-hidden mb-30 wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <img src="assets/img/projects/project-2.png" alt="" />

                  <div className="content d-flex align-items-center justify-content-between">
                    <div className="text">
                      <span className="fw-500 color-primary d-block mb-10 text-uppercase">
                        Business, Finance
                      </span>
                      <h5 className="title color-d_black">
                        Business & Strategy
                      </h5>
                    </div>

                    <a href="#" className="theme-btn">
                      <img src="assets/img/icon/arrow-right-top.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div
                  className="our-project__item overflow-hidden mb-30 wow fadeInUp"
                  data-wow-delay=".7s"
                >
                  <img src="assets/img/projects/project-3.png" alt="" />

                  <div className="content d-flex align-items-center justify-content-between">
                    <div className="text">
                      <span className="fw-500 color-primary d-block mb-10 text-uppercase">
                        Business, Finance
                      </span>
                      <h5 className="title color-d_black">Strategy</h5>
                    </div>

                    <a href="#" className="theme-btn">
                      <img src="assets/img/icon/arrow-right-top.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div
                  className="our-project__item overflow-hidden mb-30 wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <img src="assets/img/projects/project-4.png" alt="" />

                  <div className="content d-flex align-items-center justify-content-between">
                    <div className="text">
                      <span className="fw-500 color-primary d-block mb-10 text-uppercase">
                        Business, Finance
                      </span>
                      <h5 className="title color-d_black">Business</h5>
                    </div>

                    <a href="#" className="theme-btn">
                      <img src="assets/img/icon/arrow-right-top.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-sm-6">
                <div
                  className="our-project__item overflow-hidden mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <img src="assets/img/projects/project-5.png" alt="" />

                  <div className="content d-flex align-items-center justify-content-between">
                    <div className="text">
                      <span className="fw-500 color-primary d-block mb-10 text-uppercase">
                        Business, Finance
                      </span>
                      <h5 className="title color-d_black">
                        Business & Strategy
                      </h5>
                    </div>

                    <a href="#" className="theme-btn">
                      <img src="assets/img/icon/arrow-right-top.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div
                  className="our-project__item overflow-hidden mb-30 wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <img src="assets/img/projects/project-6.png" alt="" />

                  <div className="content d-flex align-items-center justify-content-between">
                    <div className="text">
                      <span className="fw-500 color-primary d-block mb-10 text-uppercase">
                        Business, Finance
                      </span>
                      <h5 className="title color-d_black">
                        Business & Strategy
                      </h5>
                    </div>

                    <a href="#" className="theme-btn">
                      <img src="assets/img/icon/arrow-right-top.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- blog-news Our Portfolio end --> */}
    </>
  );
};

export default Ourportfolios;
