import React from "react";

const Newsblog = () => {
  return (
    <>
      {/* <!-- Our Via-we blog-news start --> */}
      <section
        className="blog-news pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden"
        // style="background-image: url(assets/img/home-3/blog-new-bg.png)"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div
                className="blog-news__content wow fadeInUp"
                data-wow-delay=".3s"
              >
                <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block">
                  <img
                    src="assets/img/team-details/badge-line.svg"
                    className="img-fluid mr-10"
                    alt=""
                  />
                  Blog & News
                </span>
                <h2 className="title color-d_black">
                  Via-we Latest <span>Blog & News</span>
                </h2>
              </div>
            </div>

            <div className="col-lg-3">
              <div
                className="blog-news__content mt-md-25 mt-sm-15 mt-xs-10 text-start text-lg-end wow fadeInUp"
                data-wow-delay=".3s"
              >
                <a href="#" className="theme-btn">
                  View All Blog <i className="far fa-chevron-double-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="blog-news__bottom mt-65 mt-sm-50 mt-xs-40">
            <div className="row mb-minus-30">
              <div className="col-xl-4 col-md-6 col-12">
                <div
                  className="blog-item mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="blog-featured-thumb mb-xs-30 mb-sm-30 mb-md-35 mb-lg-40 mb-50">
                    <div className="media overflow-hidden">
                      <img
                        src="assets/img/blog/blog-1.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="date">
                      <span>27</span>
                      <span>Jun</span>
                      <span>2020</span>
                    </div>
                  </div>

                  <div className="content pr-sm-25 pr-xs-15 pl-xs-15 pl-sm-25 pr-xs-15 pr-30 pb-30 pl-30">
                    <div className="post-author mb-5">
                      <a href="#">Business, Consulting</a>
                    </div>

                    <h4>
                      <a href="#">
                        Consulted admitting wooded is power acuteness
                      </a>
                    </h4>

                    <div className="btn-link-share mt-xs-10 mt-sm-10 mt-15">
                      <a
                        href="#"
                        className="theme-btn btn-border"
                      >
                        Read More{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div
                  className="blog-item mb-30 wow fadeInUp"
                  data-wow-delay=".5s"
                >
                  <div className="blog-featured-thumb mb-xs-30 mb-sm-30 mb-md-35 mb-lg-40 mb-50">
                    <div className="media overflow-hidden">
                      <img
                        src="assets/img/blog/blog-2.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="date">
                      <span>30</span>
                      <span>April</span>
                      <span>2021</span>
                    </div>
                  </div>

                  <div className="content pr-sm-25 pr-xs-15 pl-xs-15 pl-sm-25 pr-xs-15 pr-30 pb-30 pl-30">
                    <div className="post-author mb-5">
                      <a href="#">Business</a>
                    </div>

                    <h4>
                      <a href="#">
                        The 3 Most Effective Incentives for Employees
                      </a>
                    </h4>

                    <div className="btn-link-share mt-xs-10 mt-sm-10 mt-15">
                      <a
                        href="#"
                        className="theme-btn btn-border"
                      >
                        Read More{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6 col-12">
                <div
                  className="blog-item mb-30 wow fadeInUp"
                  data-wow-delay=".7s"
                >
                  <div className="blog-featured-thumb mb-xs-30 mb-sm-30 mb-md-35 mb-lg-40 mb-50">
                    <div className="media overflow-hidden">
                      <img
                        src="assets/img/blog/blog-3.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="date">
                      <span>24</span>
                      <span>Sept</span>
                      <span>2022</span>
                    </div>
                  </div>

                  <div className="content pr-sm-25 pr-xs-15 pl-xs-15 pl-sm-25 pr-xs-15 pr-30 pb-30 pl-30">
                    <div className="post-author mb-5">
                      <a href="#">Consulting</a>
                    </div>

                    <h4>
                      <a href="#">
                        How To Achieve Success As A New Consultant
                      </a>
                    </h4>

                    <div className="btn-link-share mt-xs-10 mt-sm-10 mt-15">
                      <a
                        href="#"
                        className="theme-btn btn-border"
                      >
                        Read More{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- blog-news end --> */}
    </>
  );
};

export default Newsblog;
