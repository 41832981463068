import React from "react";

const Header = () => {
  return (
    <>
      {/* <!-- header end --> */}
      <header className="header header-1 transparent header-3 hover-color-green">
        <div className="top-header d-none d-xl-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-8">
                <div className="header-cta">
                  <ul>
                    <li>
                      <a>
                        <i className="fal fa-clock"></i> Mon – Fri: 8.00 – 18.00
                      </a>
                    </li>
                    <li>
                      <a>
                        <i className="fal fa-clock"></i> Sun – Sat: 9.00 – 12.00
                      </a>
                    </li>
                    {/* <!-- <li><a href="mailto:consulter@example.com"><i className="icon-email"></i>consulter@example.com</a></li> --> */}
                  </ul>
                </div>
              </div>

              <div className="col-4">
                <div className="header-right-socail d-flex justify-content-end align-items-center">
                  <h6 className="font-la color-white fw-600">Follow On:</h6>

                  <div className="social-profile">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/p/C7eiJNNJz3U/?igsh=MTdoamVyYzduYjhpOQ==">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-header-wraper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="header-logo">
                    <div className="logo">
                      <a href="/">
                        <img
                          src="assets/img/logo/logo.png"
                          alt="logo"
                          style={{ width: "174px", height: "40" }}
                        />
                      </a>
                    </div>
                  </div>

                  <div className="header-menu d-none d-xl-block">
                    <div className="main-menu">
                      <ul>
                        <li>
                          <a href="index.html">Home</a>
                          {/* <ul>
                            <li>
                              <a href="index.html">home 1</a>
                            </li>
                            <li>
                              <a href="index-2.html">home 2</a>
                            </li>
                            <li>
                              <a href="index-3.html">home 3</a>
                            </li>
                            <li>
                              <a href="index-4.html">home 4</a>
                            </li>
                            <li>
                              <a href="index-5.html">home 5</a>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <a href="services.html">Our Services</a>

                          <ul>
                            <li>
                              <a href="services.html">Marketing</a>
                            </li>
                            <li>
                              <a href="services-2.html">Branding</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a>Pages</a>

                          <ul>
                            <li>
                              <a href="about.html">About</a>
                            </li>
                            <li>
                              <a href="contact.html">Contact</a>
                            </li>
                            <li>
                              <a href="table.html">Pricing Table</a>
                            </li>
                            <li>
                              <a href="team.html">Team</a>
                            </li>
                            <li>
                              <a href="team-details.html">Team Details</a>
                            </li>
                            {/* <li>
                              <a href="services-details.html">
                                Services Details
                              </a>
                            </li>
                            <li>
                              <a href="our-project-details.html">
                                Our Project Details
                              </a>
                            </li>
                            <li>
                              <a href="blog-details.html">Blog Details</a>
                            </li>
                            <li>
                              <a href="404.html">404</a>
                            </li> */}
                          </ul>
                        </li>
                        <li>
                          <a href="our-project.html">Portfolio</a>
                        </li>
                        <li>
                          <a href="blog.html">Blog</a>

                          <ul>
                            <li>
                              <a href="blog.html">Blog 1</a>
                            </li>
                            {/* <li>
                              <a href="blog-standard.html">Blog 2</a>
                            </li> */}
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="header-right d-flex align-items-center">
                    <div className="header-search">
                      <a
                        className="search-toggle"
                        data-selector=".header-search"
                      >
                        <span className="fas fa-search"></span>
                      </a>

                      <form className="search-box" action="#" method="get">
                        <div className="form-group d-flex align-items-center">
                          <input
                            type="search"
                            name="s"
                            value=""
                            className="search-input"
                            id="search"
                            placeholder="Search"
                          />
                          <button type="submit" className="search-submit">
                            <i className="fas fa-search"></i>
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="horizontal-bar d-none d-md-block"></div>

                    <a
                      href="tel:+1235568824"
                      className="header-contact d-none d-md-flex align-items-center"
                    >
                      <div className="icon color-primary">
                        <i className="icon-call"></i>
                      </div>
                      <div className="text">
                        <span className="font-la mb-2 d-block fw-500">
                          Contact For Support
                        </span>
                        <h5 className="fw-500">
                          <a href="tel:+91 6304 579 933">+91 6304 579 933</a>
                        </h5>
                      </div>
                    </a>

                    <div className="mobile-nav-bar d-block ml-3 ml-sm-5 d-xl-none">
                      <div className="mobile-nav-wrap">
                        <div id="hamburger">
                          <i className="fal fa-bars"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <!-- mobile menu - responsive menu  --> */}
      <div className="mobile-nav">
        <button type="button" className="close-nav">
          <i className="fal fa-times-circle"></i>
        </button>

        <nav className="sidebar-nav">
          <div className="navigation">
            <div className="consulter-mobile-nav">
              <ul>
                <li>
                  <a href="index.html">Home</a>
                  {/* <ul>
                    <li>
                      <a href="index.html">home 1</a>
                    </li>
                    <li>
                      <a href="index-2.html">home 2</a>
                    </li>
                    <li>
                      <a href="index-3.html">home 3</a>
                    </li>
                    <li>
                      <a href="index-4.html">home 4</a>
                    </li>
                    <li>
                      <a href="index-5.html">home 5</a>
                    </li>
                  </ul> */}
                </li>
                <li>
                  <a href="services.html">Our Services</a>

                  <ul>
                    <li>
                      <a href="services.html">Marketing</a>
                    </li>
                    <li>
                      <a href="services-2.html">Branding</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a>Pages</a>

                  <ul>
                    <li>
                      <a href="about.html">About</a>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                    <li>
                      <a href="table.html">Pricing Table</a>
                    </li>
                    <li>
                      <a href="team.html">Team</a>
                    </li>
                    <li>
                      <a href="team-details.html">Team Details</a>
                    </li>
                    {/* <li>
                      <a href="services-details.html">Services Details</a>
                    </li>
                    <li>
                      <a href="our-project-details.html">Our Project Details</a>
                    </li>
                    <li>
                      <a href="blog-details.html">Blog Details</a>
                    </li>
                    <li>
                      <a href="404.html">404</a>
                    </li> */}
                  </ul>
                </li>
                <li>
                  <a href="our-project.html">Portfolio</a>
                </li>
                <li>
                  <a href="blog.html">Blog</a>

                  <ul>
                    <li>
                      <a href="blog.html">Blog 1</a>
                    </li>
                    {/* <li>
                      <a href="blog-standard.html">Blog 2</a>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </div>

            <div className="sidebar-nav__bottom mt-20">
              <div className="sidebar-nav__bottom-contact-infos mb-20">
                <h6 className="color-black mb-5">Contact Info</h6>

                <ul>
                  <li>
                    <a>
                      <i className="fal fa-clock"></i> Mon – Fri: 8.00 – 18.00
                    </a>
                  </li>
                  <li>
                    <a href="mailto:consulter@example.com">
                      <i className="icon-email"></i>via.we.org@gmail.com
                    </a>
                  </li>
                  <li>
                    <a className="header-contact d-flex align-items-center">
                      <div className="icon">
                        <i className="icon-call"></i>
                        {/* <!-- <img src="assets/img/icon/phone-1.svg" alt=""> --> */}
                      </div>
                      <div className="text">
                        <span className="font-la mb-5 d-block fw-500">
                          Contact For Support
                        </span>
                        <h5 className="fw-500">
                          <a href="tel:+91 6304 579 933">+91 6304 579 933</a>
                        </h5>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="sidebar-nav__bottom-social">
                <h6 className="color-black mb-5">Follow On:</h6>

                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/p/C7eiJNNJz3U/?igsh=MTdoamVyYzduYjhpOQ==">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="offcanvas-overlay"></div>
      {/* <!-- offcanvas-overlay --> */}
      {/* <!-- header end --> */}
    </>
  );
};

export default Header;
