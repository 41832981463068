import React from "react";

const Ctabanner = () => {
  return (
    <>
      {/* <!-- cta start --> */}
      <section className="cta-banner overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="cta-banner__content d-flex flex-column flex-lg-row justify-content-between align-items-center pl-xs-20 pr-xs-20 pl-sm-30 pr-sm-30 pl-lg-50 pr-lg-50 pr-85 pl-85 overflow-hidden">
                <div
                  className="cta-banner__content-text wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <h3 className="title text-capitalize color-white">
                    Small Business Grow Fast With Our Consulting Services
                  </h3>
                </div>
                <div
                  className="cta-banner__content-btn wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <a href="#" className="theme-btn btn-white">
                    Let’s Work Together
                    <i className="far fa-chevron-double-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- cta start --> */}
    </>
  );
};

export default Ctabanner;
