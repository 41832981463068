import React from "react";
import Header from "./Header/Header";
import Banner from "./Banner/Banner";
import Competitive from "./Competitive/Competitive";
import Employservices from "./Employservices/Employservices";
import Companyskills from "./Companyskills/Companyskills";
import Footer from "./Footer/Footer";
import Counterarea from "./Counterarea/Counterarea";
import Ourportfolios from "./Ourportfolios/Ourportfolios";
import Ourteam from "./Ourteam/Ourteam";
import Ctabanner from "./Ctabanner/Ctabanner";
import Newsblog from "./Newsblog/Newsblog";
import Freeconsultation from "./Consultation/Freeconsultation";
import Youtube from "./Youtube/Youtube";

const Blog = () => {
  return (
    <>
      <Header />
      <Banner />
      <Competitive />
      <Employservices />
      <Companyskills />
      <Counterarea />
      <Ourportfolios />
      <Ourteam />
      <Youtube />
      <Freeconsultation />
      <Newsblog />
      <Ctabanner />
      <Footer />
    </>
  );
};

export default Blog;
