import React from "react";

const Footer = () => {
  return (
    <>
      {/* <!-- footer start --> */}
      <footer className="footer-1 overflow-hidden">
        <div className="footer-top mb-xs-5 mb-sm-10 mb-md-15 mb-lg-20 mb-25 overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="single-footer-wid site_info_box">
                  <a href="#" className="d-block mb-20">
                    <img
                      src="assets/img/logo/logo.png"
                      alt="logo"
                      style={{ width: "174px", height: "40" }}
                    />
                  </a>

                  <div className="description font-la color-white">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua ut enim ad minim veniam.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="single-footer-wid newsletter_widget">
                  <h6 className="title d-flex align-items-center color-white mb-30">
                    <img src="assets/img/icon/notification.svg" alt="" /> keep
                    up to date - get updates with latest topics.
                  </h6>

                  <div className="newsletter_box">
                    <form action="#">
                      <input
                        type="email"
                        placeholder="Enter your email address"
                        required
                      />
                      <button className="theme-btn" type="submit">
                        Subscribe Now <i className="fab fa-telegram-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xl-3">
              <div className="single-footer-wid contact_widget">
                <h4 className="wid-title mb-30 color-white">Working Time</h4>

                <div className="contact-wrapper pt-30 pr-30 pb-30 pl-30">
                  <div className="wid-contact pb-30 mb-25">
                    <ul>
                      <li>
                        <div className="icon">
                          <i className="far fa-clock"></i>
                        </div>

                        <div className="contact-info font-la color-white">
                          <p>Mon - Sat / 08am : 12pm</p>
                        </div>
                      </li>

                      <li>
                        <div className="icon">
                          <i className="far fa-clock"></i>
                        </div>

                        <div className="contact-info font-la color-white">
                          <p>Mon - Sat / 08am : 12pm</p>
                        </div>
                      </li>

                      <li>
                        <div className="icon">
                          <i className="far fa-clock"></i>
                        </div>

                        <div className="contact-info font-la color-white">
                          <p>Sunday Close</p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="social-profile">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.col-lg-3 - single-footer-wid --> */}

            <div className="col-md-6 col-xl-2">
              <div className="single-footer-wid pl-xl-10 pl-50">
                <h4 className="wid-title mb-30 color-white">Quick Link</h4>

                <ul>
                  <li>
                    <a href="#">About Company</a>
                  </li>
                  <li>
                    <a href="#">Our Services</a>
                  </li>
                  <li>
                    <a href="#">Investor Presentation</a>
                  </li>
                  <li>
                    <a href="#">Investor Career</a>
                  </li>
                  <li>
                    <a href="#">Meet Our Team</a>
                  </li>
                  <li>
                    <a href="#">Support</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- /.col-lg-2 - single-footer-wid --> */}

            <div className="col-md-6 col-xl-4">
              <div className="single-footer-wid recent_post_widget pl-xl-10 pl-65 pr-50 pr-xl-30">
                <h4 className="wid-title mb-30 color-white">Resent Post</h4>

                <div className="recent-post-list">
                  <a
                    href="#"
                    className="single-recent-post mb-20 pb-20 d-flex align-items-center"
                  >
                    <div className="thumb">
                      <img src="assets/img/footer/resent-post-1.png" alt="" />
                    </div>

                    <div className="post-data">
                      <h5 className="color-white mb-10 fw-600">
                        Why Purpose-Driven Employers Succeed
                      </h5>
                      <span className="color-white d-flex ailign-items-center">
                        <i className="far fa-clock"></i>January 11, 2018
                      </span>
                    </div>
                  </a>

                  <a
                    href="#"
                    className="single-recent-post mb-20 pb-20 d-flex align-items-center"
                  >
                    <div className="thumb">
                      <img src="assets/img/footer/resent-post-2.png" alt="" />
                    </div>

                    <div className="post-data">
                      <h5 className="color-white mb-10 fw-600">
                        Saving Time Achieving Success in Business
                      </h5>
                      <span className="color-white d-flex ailign-items-center">
                        <i className="far fa-clock"></i>november 12, 2019
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- /.col-lg-4 - single-footer-wid --> */}

            <div className="col-md-6 col-xl-3">
              <div className="single-footer-wid">
                <h4 className="wid-title mb-30 color-white">Office Location</h4>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.8538466759316!2d80.631853!3d16.5104634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTbCsDMwJzM3LjYiTiA4MMKwMzgnMDAuOCJF!5e0!3m2!1sen!2sin!4v1686747753530!5m2!1sen!2sin"
                  style={{ border: "0px" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            {/* <!-- /.col-lg-3 - single-footer-wid --> */}
          </div>
        </div>

        <div className="footer-bottom overflow-hidden">
          <div className="container">
            <div className="footer-bottom-content d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div className="coppyright text-center text-md-start">
                © 2024 All Rights Reserved @ <a href="#">Via-We</a>
                {/* <a href="#">VIA-WE</a> */}
              </div>

              <div className="footer-bottom-list last_no_bullet">
                <ul>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- footer end --> */}
    </>
  );
};

export default Footer;
