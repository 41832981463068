import React from "react";

const Employservices = () => {
  return (
    <>
      {/* <!-- employee-friendly  Services start --> */}
      <section className="employee-friendly pt-xs-80 pt-sm-100 pt-md-100 pt-120 overflow-hidden">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-9">
              <div
                className="employee-friendly__content wow fadeInUp"
                data-wow-delay=".3s"
              >
                <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block">
                  <img
                    src="assets/img/team-details/badge-line.svg"
                    className="img-fluid mr-10"
                    alt=""
                  />
                  Services
                </span>
                <h2 className="title color-d_black">
                  Employee Friendly <span>Service</span>
                </h2>
              </div>
            </div>

            <div className="col-sm-3">
              <div
                className="slider-controls mt-sm-30 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="slider-arrows d-flex align-content-center justify-content-sm-end"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div
                className="employee-friendly__slider mt-65 mt-md-50 mt-sm-40 mt-xs-30 wow fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="slider-item">
                  <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                    <div className="top d-flex align-items-center">
                      <div className="icon color-white text-center">
                        <i className="fal fa-analytics"></i>
                      </div>

                      <h4 className="title color-d_black">
                        <a href="#">Finance &amp; Strategy Service</a>
                      </h4>
                    </div>

                    <div className="bottom">
                      <div className="media overflow-hidden">
                        <img
                          src="assets/img/services/services-2.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>

                      <a href="#" className="theme-btn text-center fw-600">
                        Read Details{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="slider-item">
                  <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                    <div className="top d-flex align-items-center">
                      <div className="icon color-white text-center">
                        <i className="icon-planning"></i>
                      </div>

                      <h4 className="title color-d_black">
                        <a href="#">
                          Business Planning, Strategy &amp; Execution
                        </a>
                      </h4>
                    </div>

                    <div className="bottom">
                      <div className="media overflow-hidden">
                        <img
                          src="assets/img/project-details/project-details-2.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>

                      <a href="#" className="theme-btn text-center fw-600">
                        Read Details{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="slider-item">
                  <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                    <div className="top d-flex align-items-center">
                      <div className="icon color-white text-center">
                        <i className="icon-outline"></i>
                      </div>

                      <h4 className="title color-d_black">
                        <a href="#">Financial Projections And Analysis</a>
                      </h4>
                    </div>

                    <div className="bottom">
                      <div className="media overflow-hidden">
                        <img
                          src="assets/img/project-details/project-details-3.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>

                      <a href="#" className="theme-btn text-center fw-600">
                        Read Details{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="slider-item">
                  <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                    <div className="top d-flex align-items-center">
                      <div className="icon color-white text-center">
                        <i className="fal fa-analytics"></i>
                      </div>

                      <h4 className="title color-d_black">
                        <a href="#">Finance &amp; Strategy Service</a>
                      </h4>
                    </div>

                    <div className="bottom">
                      <div className="media overflow-hidden">
                        <img
                          src="assets/img/services/services-2.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>

                      <a href="#" className="theme-btn text-center fw-600">
                        Read Details{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="slider-item">
                  <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                    <div className="top d-flex align-items-center">
                      <div className="icon color-white text-center">
                        <i className="icon-planning"></i>
                      </div>

                      <h4 className="title color-d_black">
                        <a href="#">
                          Business Planning, Strategy &amp; Execution
                        </a>
                      </h4>
                    </div>

                    <div className="bottom">
                      <div className="media overflow-hidden">
                        <img
                          src="assets/img/project-details/project-details-2.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>

                      <a href="#" className="theme-btn text-center fw-600">
                        Read Details{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="slider-item">
                  <div className="similar-work__item reade-more-hidden d-flex justify-content-between flex-column">
                    <div className="top d-flex align-items-center">
                      <div className="icon color-white text-center">
                        <i className="icon-outline"></i>
                      </div>

                      <h4 className="title color-d_black">
                        <a href="#">Financial Projections And Analysis</a>
                      </h4>
                    </div>

                    <div className="bottom">
                      <div className="media overflow-hidden">
                        <img
                          src="assets/img/project-details/project-details-3.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>

                      <a href="#" className="theme-btn text-center fw-600">
                        Read Details{" "}
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- employee-friendly end --> */}
    </>
  );
};

export default Employservices;
