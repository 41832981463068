import React from "react";

const Counterarea = () => {
  return (
    <>
      {/* <!-- Counterarea start --> */}
      <div className="counter-area pb-xs-80 pb-sm-100 pb-md-100 pb-120 overflow-hidden">
        <div className="container">
          <div className="row mb-minus-30">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="counter-area__item d-flex align-items-center wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="icon color-primary">
                  <i className="icon-process-1"></i>
                </div>

                <div className="text text-center">
                  <div className="number fw-600 color-primary">
                    <span className="counter">5620</span>+
                  </div>
                  <div className="description font-la">Successful Project</div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="counter-area__item d-flex align-items-center wow fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="icon color-primary">
                  <i className="icon-support-2"></i>
                </div>

                <div className="text text-center">
                  <div className="number fw-600 color-primary">
                    <span className="counter">150</span>+
                  </div>
                  <div className="description font-la">Expert Consulter</div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="counter-area__item d-flex align-items-center wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div className="icon color-primary">
                  <i className="icon-coffee-2"></i>
                </div>

                <div className="text text-center">
                  <div className="number fw-600 color-primary">
                    <span className="counter">96</span>+
                  </div>
                  <div className="description font-la">Cup Of Cofee</div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="counter-area__item d-flex align-items-center wow fadeInUp"
                data-wow-delay=".9s"
              >
                <div className="icon color-primary">
                  <i className="icon-teamwork-1"></i>
                </div>

                <div className="text text-center">
                  <div className="number fw-600 color-primary">
                    <span className="counter">3225</span>+
                  </div>
                  <div className="description font-la">Client Satisfaction</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- counter-area end --> */}
    </>
  );
};

export default Counterarea;
