import React from 'react'

const Youtube = () => {
  return (
   <>
       {/* <!-- Youtube delivery start --> */}
    <section
      className="delivery pt-xs-80 pb-sm-100 pt-md-100 pt-120 overflow-hidden"
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="delivery__content mb-65 mb-md-60 mb-sm-50 mb-xs-40 text-center wow fadeInUp"
              data-wow-delay=".3s"
            >
              <h2 className="title">
                From Strategy to Delivery <span>On Time Every Time</span>
              </h2>
            </div>

            <div className="delivery__media">
              <img
                src="assets/img/home-3/delivery.png"
                className="img-fluid"
                alt=""
              />

              <div className="delivery__media-theme-btn-wrapper">
                <a
                  // href="https://www.youtube.com/watch?v=9xwazD5SyVg"
                  href="#"
                  className="popup-video"
                  data-effect="mfp-move-from-top"
                  ><img src="assets/img/icon/play-transparent.svg" alt=""
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- Youtubedelivery end --> */}
   </>
  )
}

export default Youtube