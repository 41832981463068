import React from "react";

const Banner = () => {
  return (
    <>
      {/* <!-- page-banner start --> */}
      <section
        className="banner overflow-hidden"
        // style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner/banner-3.png)`}}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="banner__content wow fadeInLeft"
                data-wow-delay=".5s"
              >
                <span className="sub-title fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-15 d-block">
                  Welcome To Via-We
                </span>
                <h1 className="title color-d_black mb-sm-10 mb-xs-5 mb-15 wow fadeInUp">
                  Best Grow Your <span>Business</span>
                </h1>

                <div className="description font-la mb-40 mb-lg-35 mb-sm-30 mb-xs-25">
                  <p>
                  Enhance your business growth by leveraging innovative marketing strategies and cutting-edge technology. Focus on delivering exceptional customer experiences to build loyalty and trust. 
                  </p>
                </div>

                <a href="about.html" className="theme-btn">
                  Free Consultation{" "}
                  <i className="far fa-chevron-double-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- page-banner end --> */}
    </>
  );
};

export default Banner;
