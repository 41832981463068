import React from "react";

const Competitive = () => {
  return (
    <>
      {/* <!-- competitive-edge start --> */}
      <section className="competitive-edge overflow-hidden">
        <div className="container">
          <div className="row align-items-end mb-minus-30">
            <div className="col-xxl-4 col-lg-6">
              <div
                className="competitive-edge__item d-flex align-items-center justify-content-between wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="left d-flex align-items-center">
                  <div className="icon color-primary">
                    <i className="icon-process-1"></i>
                  </div>
                  <h6 className="title text-capitalize color-d_black">
                    Build Your Business With Right Way
                  </h6>
                </div>

                <a href="#" className="arrow-right">
                  <i className="icon-arrow-right-2"></i>
                </a>
              </div>
            </div>

            <div className="col-xxl-4 col-lg-6">
              <div
                className="competitive-edge__item d-flex align-items-center justify-content-between wow fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="left d-flex align-items-center">
                  <div className="icon color-primary">
                    <i className="like-comment"></i>
                  </div>
                  <h6 className="title text-capitalize color-d_black">
                    We Take Care and Growth Your Business
                  </h6>
                </div>

                <a href="#" className="arrow-right">
                  <i className="icon-arrow-right-2"></i>
                </a>
              </div>
            </div>

            <div className="col-xxl-4 col-lg-12">
              <div
                className="competitive-edge__item d-flex competitive-edge__item-video justify-content-between flex-column wow fadeInUp"
                data-wow-delay=".7s"
              >
                <div className="left">
                  <h6 className="title text-capitalize color-white mb-10 mb-xs-5">
                    find a new competitive edge
                  </h6>
                  <div className="description font-la color-white">
                    <p>
                      Discover innovative strategies to outpace competitors and
                      redefine your market position.
                    </p>
                  </div>
                </div>

                <div className="arrow-right d-flex justify-content-between flex-row align-items-end">
                  <span>
                    Watch Video
                    {/* <!-- <i className="icon-arrow-right"></i> --> */}
                    <img src="assets/img/icon/arrow-right-big-alt.svg" alt="" />
                  </span>
                  <a
                    href="#"
                    className="popup-video"
                    data-effect="mfp-move-from-top"
                  >
                    <i className="icon-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- competitive-edge end --> */}
    </>
  );
};

export default Competitive;
