import React from "react";

const Companyskills = () => {
  return (
    <>
      {/* <!-- Companyskill start --> */}
      <section className="company-skill company-skill-home-3 pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-90 overflow-hidden">
        <div className="container">
          <a
            href="#"
            className="popup-video"
            data-effect="mfp-move-from-top"
          >
            <i className="icon-play"></i>
          </a>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div
                className="our-company__meida wow fadeInUp"
                data-wow-delay=".3s"
              >
                <img
                  src="assets/img/about/our-company-1.png"
                  alt=""
                  className="img-fluid"
                />

                <div className="years-experience overflow-hidden mt-20 mt-sm-10 mt-xs-10 text-center">
                  <div className="number mb-5 color-white">
                    <span className="counter">23</span>
                    <sup>+</sup>
                  </div>

                  <h5 className="title color-white">Years Experience</h5>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div
                className="our-company__meida border-radius wow fadeInUp"
                data-wow-delay=".5s"
              >
                <img
                  src="assets/img/about/our-company-2.png"
                  alt=""
                  className="img-fluid"
                />

                <div className="horizental-bar"></div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="company-skill__content mt-md-50 mt-sm-45 mt-xs-40 ml-auto wow fadeInUp"
                data-wow-delay=".7s"
              >
                <span className="sub-title d-block fw-500 color-primary text-uppercase mb-sm-10 mb-xs-5 mb-md-15 mb-20">
                  <img
                    src="assets/img/team-details/badge-line.svg"
                    className="img-fluid mr-10"
                    alt=""
                  />
                  Company Skills
                </span>
                <h2 className="title color-pd_black mb-25 mb-xs-10 mb-sm-15">
                  Our Company Provide <span>High Quality Idea</span>
                </h2>

                <div className="description font-la">
                  <p>
                    At Via-We Company, we specialize in delivering high-quality,
                    innovative ideas that drive business growth and success. Our
                    solutions are designed to meet the unique needs of our
                    clients, ensuring exceptional results every time.
                  </p>
                </div>

                <div className="progress-bar__wrapper mt-40 mt-sm-35 mt-xs-30">
                  <div
                    className="single-progress-bar mb-30 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    <h6 className="title color-d_black mb-10">Web Design</h6>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="80"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ maxWidth: "80%" }}
                      >
                        <span className="placeholder" style={{ left: "80%" }}>
                          80%
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="single-progress-bar mb-30 wow fadeInUp"
                    data-wow-delay=".5s"
                  >
                    <h6 className="title color-d_black mb-10">
                      Web Development
                    </h6>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="55"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ maxWidth: "55%" }}
                      >
                        <span className="placeholder" style={{ left: "55%" }}>
                          55%
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="single-progress-bar mb-30 wow fadeInUp"
                    data-wow-delay=".7s"
                  >
                    <h6 className="title color-d_black mb-10">
                      Web Application
                    </h6>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="90"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ maxWidth: "90%" }}
                      >
                        <span className="placeholder" style={{ left: "90%" }}>
                          90%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- company-skill end --> */}
    </>
  );
};

export default Companyskills;
